import { defineStore } from "pinia";
import axios from "axios";

function findWithAttr(array, attr, value) {
  for (let i = 0; i < array.length; i += 1)
    if (array[i][attr] === value)
      return i;

  return -1;
}

export const useStoreStore = defineStore("store", {
  state: () => ({
    products: [],
  }),
  actions: {
    updateProducts(productsList) {
      this.products = productsList;
    },
    getProduct(productId) {
      const productIndex = findWithAttr(this.products, "id", productId);

      return this.products[productIndex];
    },

    async fetchProduct(productId) {
      const config = useRuntimeConfig();

      const productData = (await axios.get(`${config.public.apiBase}/getProduct?productId=${productId}`, {
        headers: {
          Authorization: `Bearer ${config.public.apiToken}`,
        },
      })).data.product;

      const productIndex = findWithAttr(this.products, "id", productId);

      if (productIndex > -1) this.products[productIndex] = productData;
      else this.products.push(productData);

      return productData;
    },
  },
});
